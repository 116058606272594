import { isValidNif } from "nif-dni-nie-cif-validation";
import EnumService from "./enum.service";
import UserService from "./user.service";
import gesioneEsitiIncaricoNominatoHook from "../hooks/esitiHooks/esitiIncarico/gesioneEsitiIncaricoNominatoHook";

const translateQueueDesc = (queueDesc) => {
  return queueDesc?.replace("RE - ", "").replace("TL - ", "").replace("MN - ", "").replace("TP - ", "").replace("I4 - ", "").replace("MM - ", "");
}

const translateQueueDescLanguage = (value,currentLanguage) => {
  return UserService.translateCriterioByLanguage(value?.toLowerCase().slice(5),currentLanguage).replace("ISTRUTTORIA", "SINISTRO").replace("INVESTIGACION", "SINIESTRO").replace("CHIUSA", "");
}

const isValidateText = (textToValidate) => {
  let validate = false;
  if (textToValidate?.length >= 2) {
    validate = true;
  }
  return validate
}

const isValidCodiceFiscale = (codiceFiscale) => {
  let validate = false;
  const regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9A-Za-z]{3}[A-Za-z]$/;
  if (regex.test(codiceFiscale)) {
    validate = true;
  } else if(isValidNif(codiceFiscale)){
    validate = true
  }
  return validate
}

const isValidPartitaIVA = (piva) => {
  let validate = false;
  const regex = /^[0-9]{11}$/;
  if (regex.test(piva)) {
    validate = true;
  } else if(isValidNif(piva)){
    validate = true
  }
  return validate
}

const isValidPIvaOrCodFiscale = (pivaOrCodFiscale) => {
  let validate = false;
  if(isValidCodiceFiscale(pivaOrCodFiscale)){
    validate = true
  }else if(isValidPartitaIVA(pivaOrCodFiscale)){
    validate = true
  }else if(isValidNif(pivaOrCodFiscale)){
    validate = true
  }
  return validate
}

const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^[0-9]{5,10}$/;
  return !!phoneRegex.test(phoneNumber)
}

const isValidEmailNew = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}



const isNumeric = (value) => {
  return /^-?\d+(\.\d+)?$/.test(value);
}


/**
 * Funzione per validare se un IBAN è italiano
 * @param {String} iban 
 * @returns se l'IBAN è italiano
*/
const isItalianIban = (iban) => {
  if (iban !== undefined && iban !== null && iban !== '') {
    return /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/.test(iban);  
  }
  return false;
};

/**
 * Funzione per determinare se un IBAN appartiene al circuito SEPA
 * @param {String} iban 
 * @returns se l'IBAN appartiene al circuito SEPA
*/
const isSepaIban = (iban) => {
  if(iban !== undefined && iban !== null && iban !== '') {
      const sepaCountries = [
        "AT", "AD", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", 
        "FI", "FR", "DE", "GI", "GR", "HU", "IS", "IE", "IT", 
        "LV", "LI", "LT", "LU", "MT", "MC", "NL", "NO", "PL", 
        "PT", "RO", "SM", "SK", "SI", "ES", "SE", "CH", "GB"
      ];
    const countryCode = iban?.slice(0, 2);
    return sepaCountries?.includes(countryCode);
  }
  return false;
};


const gestioneDisableRiserveSoggCoinvonti = (arrValidazioni) => {
  let validate = false;
  const validazioneToCheck = arrValidazioni?.find(item => item?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO' && item?.statoEsitazione === 'In attesa');
  if (validazioneToCheck){
    validate = true
  }
  return validate
}

const gestionePulsanteIcarichiIstruttoria = (arrValidazioni, riservaSpesa, importoPagamentoSpesa) => {
  if(importoPagamentoSpesa > riservaSpesa){
    const validazione = arrValidazioni?.find(item => item?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE'
                                            && (Math.abs(item?.valoreDaAutorizzare - importoPagamentoSpesa) < 1));
    if(arrValidazioni && arrValidazioni?.length > 0){
      if(validazione?.statoEsitazione === ('In attesa') 
      ){
        return true
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
        && (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoSpesa) < 1)
      ){
        return false
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazione?.statoEsitazione.toLowerCase())){
        return true
      }
    }
    return true
  }
  return false
}

const gestioneSelectPagSpese = (arrValidazioni, riservaSpesa, importoPagamentoSpesa, totalePagamentoSpese) => {
  if(importoPagamentoSpesa > riservaSpesa){
    const validazione = arrValidazioni?.find(item => item?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE');
    const validazioneLimitePagSpese = arrValidazioni?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_SPESE');
    if(arrValidazioni && arrValidazioni?.length > 0){
      if(validazione?.statoEsitazione === ('In attesa') 
      // && validazione?.valoreDaAutorizzare === importoPagamentoSpesa
      ){
        return true
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
         && (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoSpesa) < 1)
      // && validazione?.valoreDaAutorizzare === importoPagamentoSpesa
      ){
        if(totalePagamentoSpese > riservaSpesa){
          if(
            validazioneLimitePagSpese?.statoEsitazione === ('In attesa') 
            // &&
            // validazioneLimitePagSpese?.valoreDaAutorizzare === totalePagamentoSpese
          ){
            return true
          }
          if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagSpese?.statoEsitazione.toLowerCase())
            // &&
            // validazioneLimitePagSpese?.valoreDaAutorizzare === totalePagamentoSpese
            &&
            (Math.abs(validazioneLimitePagSpese?.valoreDaAutorizzare - totalePagamentoSpese) < 1)
          ){
            return false
          }
          if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazioneLimitePagSpese?.statoEsitazione.toLowerCase())
            // &&
            // validazioneLimitePagSpese?.valoreDaAutorizzare === totalePagamentoSpese
          ){
            return true
          }
          return true
        }
        return false
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazione?.statoEsitazione.toLowerCase())){
        return true
      }
      if(totalePagamentoSpese > riservaSpesa){
        if(
          validazioneLimitePagSpese?.statoEsitazione === ('In attesa') 
          // &&
          // validazioneLimitePagSpese?.valoreDaAutorizzare === totalePagamentoSpese
        ){
          return true
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagSpese?.statoEsitazione.toLowerCase())
          && (Math.abs(validazioneLimitePagSpese?.valoreDaAutorizzare - totalePagamentoSpese) < 1)
          // &&
          // validazioneLimitePagSpese?.valoreDaAutorizzare === totalePagamentoSpese
        ){
          return false
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagSpese?.statoEsitazione.toLowerCase())
        ){
          return true
        }
        return true
      }
    }
    return true
  }
  return false
}

/* 
tipoValidazione(pin):"VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO"
 */

const gestioneSelectPagIndennizzo = (arrValidazioni, riservaIndennizzo, importoPagamentoIndennizzo, totalePagIndennizzo) => {
  if(importoPagamentoIndennizzo > riservaIndennizzo){
    if(arrValidazioni && arrValidazioni?.length > 0){
      const validazione = arrValidazioni?.find(elemento => (elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO') 
                                                           && (Math.abs(elemento?.valoreDaAutorizzare - importoPagamentoIndennizzo) < 1) );
      const validazioneLimitePagamentoIndennizzo = arrValidazioni?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO');
      if(
          validazioneLimitePagamentoIndennizzo?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
          validazioneLimitePagamentoIndennizzo?.statoEsitazione === ('In attesa')
        )
        {
          return true
      }
      if(
        validazioneLimitePagamentoIndennizzo?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagamentoIndennizzo?.statoEsitazione.toLowerCase()) //EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO )
        &&
         (Math.abs(validazioneLimitePagamentoIndennizzo?.valoreDaAutorizzare - importoPagamentoIndennizzo) < 1)
      ){
        if(validazione?.statoEsitazione === ('In attesa') 
        // && validazione?.valoreDaAutorizzare === importoPagamentoIndennizzo
        ){
          return true
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
           && (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoIndennizzo) < 1)
          ){
          return false
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazione?.statoEsitazione.toLowerCase())){
          return true
        } 
      }
      if(totalePagIndennizzo > riservaIndennizzo){
        if(validazioneLimitePagamentoIndennizzo?.statoEsitazione === ('In attesa') 
        // && validazioneLimitePagamentoIndennizzo?.valoreDaAutorizzare === totalePagIndennizzo
        ){
          return true
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagamentoIndennizzo?.statoEsitazione.toLowerCase()) //EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO 
           //&& validazioneLimitePagamentoIndennizzo?.valoreDaAutorizzare === totalePagIndennizzo
           && (Math.abs(validazioneLimitePagamentoIndennizzo?.valoreDaAutorizzare - totalePagIndennizzo) < 1)
          ){
            return false
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazioneLimitePagamentoIndennizzo?.statoEsitazione.toLowerCase())){
          return true
        } 
        return true
      }
      if(
        validazione?.statoEsitazione === ('In attesa') 
        // && validazione?.valoreDaAutorizzare === importoPagamentoIndennizzo
        ){
        return true
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes( validazione?.statoEsitazione.toLowerCase())
        // && validazione?.valoreDaAutorizzare === importoPagamentoIndennizzo
        && (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoIndennizzo) < 1)
        ){
        return false
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazione?.statoEsitazione.toLowerCase())){
        return true
      } 
    }
    return true
  }
  return false
}

const getDisabledEsitiValueIstruttoria = (riservaIndennizzo, taskCompletedCheck, riservaIndennizzoCheck, riservaSpesaCheck, esito, validazioniIstruttoria, authRiservaSpese, checkStatoPagamentoIndennizzo, listaPagamentiIndennizzo, riservaIndennizzoToValidate, checkStatoAllDanneggiati, taskCompletedCheckSenzaPagamentiLqInd, queueDesc, checkStatoSomeDanneggiatoOpen, checkArrayStatoPagamentiInviato, checkArrayStatoPagamentiNoInviato, checkArrayStatoPagamentiNotAllOk) => {
  const esitoDescription = esito?.description?.toLowerCase();

  // Caso A
  if (riservaIndennizzoCheck > riservaIndennizzo) {
    if (validazioniIstruttoria && validazioniIstruttoria?.length > 0) {
      const validazione = validazioniIstruttoria?.find(elemento => (elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO') 
                                                                && (Math.abs(elemento?.valoreDaAutorizzare - riservaIndennizzoCheck) < 1) );
      const validazioneLimitePagamentoIndennizzo = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO');
      
      //Caso Riserva spese
      if(riservaSpesaCheck > authRiservaSpese) {
        if (validazioniIstruttoria && validazioniIstruttoria?.length > 0) {
          const validazioneRiservaSpese = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE');
          if(
            validazioneRiservaSpese?.statoEsitazione === ('In attesa') 
            // &&
            // validazioneRiservaSpese?.valoreDaAutorizzare === riservaSpesaCheck
          ){ 
            return(
              !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())
            )
          }
          if(
            EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneRiservaSpese?.statoEsitazione.toLowerCase()) 
            // && 
            // validazioneRiservaSpese?.valoreDaAutorizzare === riservaSpesaCheck
            &&
            (Math.abs(validazioneRiservaSpese?.valoreDaAutorizzare - riservaSpesaCheck) < 1)
          ){
          if(riservaIndennizzoCheck > riservaIndennizzo){
            if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
              //  &&
              // validazione?.valoreDaAutorizzare === riservaIndennizzoCheck
              &&
             (Math.abs(validazione?.valoreDaAutorizzare - riservaIndennizzoCheck) < 1)
              ){
              //Caso sininistro senza seguito attivo
              if (taskCompletedCheck && (riservaIndennizzoToValidate === 0 /* &&  riservaSpesaCheck === 0 */) && (!checkStatoPagamentoIndennizzo)) {
                if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
                  if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
                    return(
                      !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                    )
                  }
                  return(
                    !EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase())
                  )
                }
                return (
                  EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
                  EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                );
              }
              //Caso sinsistro chiuso Totale
              if (taskCompletedCheckSenzaPagamentiLqInd && (riservaIndennizzoToValidate === 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato  && listaPagamentiIndennizzo?.length > 0) && checkStatoAllDanneggiati) {
                if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
                  if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
                    return(
                      !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                    )
                  }
                  return(
                    !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
                  )
                }
                return (
                  //EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
                  EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) || 
                  EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                );
              }
              //Caso sinsistro chiuso Parziale
              if (taskCompletedCheckSenzaPagamentiLqInd &&  (riservaIndennizzoToValidate > 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato && listaPagamentiIndennizzo?.length > 0)){
                return (
                  EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
                  EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                );
              }
              //Caso Sinistro Completato
              if(taskCompletedCheck && (riservaIndennizzoToValidate === 0) &&(checkArrayStatoPagamentiNoInviato && checkArrayStatoPagamentiNotAllOk && listaPagamentiIndennizzo?.length > 0)){
                if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
                  if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
                    return(
                      !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                    )
                  }
                  return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && esitoDescription !== EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
                }
                return (
                  EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
                  EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                )
              }
              //Caso istruttoria chiusa
              if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
                if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
                  return(
                    !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
                  )
                }
                return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
              }
              //Caso danneaggiati non tutti chiusi
              if(!checkStatoAllDanneggiati){
                return (
                  EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
                  EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
                  EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase())
                );
              }
            } 
            if(
              validazione?.statoEsitazione === 'In attesa' 
              // &&
              // validazione?.valoreDaAutorizzare === riservaIndennizzoCheck
            ){
              return (
                !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
              );
            }
            return true
          }
            return false
          }
          return true
        }
        return true
      }
      //caso riserva indennizzo
      if (
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
        &&
        (Math.abs(validazione?.valoreDaAutorizzare - riservaIndennizzoCheck) < 1)
      ) {
        //Caso sininistro senza seguito attivo
        if (taskCompletedCheck && (riservaIndennizzoToValidate === 0 /* &&  riservaSpesaCheck === 0 */) && (!checkStatoPagamentoIndennizzo)) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Totale
        if ( taskCompletedCheckSenzaPagamentiLqInd && (riservaIndennizzoToValidate === 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato  && listaPagamentiIndennizzo?.length > 0) && checkStatoAllDanneggiati) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            //EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) || 
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Parziale
        if (taskCompletedCheckSenzaPagamentiLqInd &&  (riservaIndennizzoToValidate > 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato && listaPagamentiIndennizzo?.length > 0)){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso Sinistro Completato
        if(taskCompletedCheck && (riservaIndennizzoToValidate === 0) &&(checkArrayStatoPagamentiNoInviato && checkArrayStatoPagamentiNotAllOk && listaPagamentiIndennizzo?.length > 0)){
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          )
        }
        //Caso istruttoria chiusa
        if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
          if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
            return(
              !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
            )
          }
          return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
        }
        //Caso danneggiati non tutti chiusi
        if(!checkStatoAllDanneggiati){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase())
          );
        }
        return (EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) );
      }
      if (
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO' &&
        validazione?.statoEsitazione === 'In attesa'
      ) {
        return (!EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) );
      }
      if(validazioneLimitePagamentoIndennizzo?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneLimitePagamentoIndennizzo?.statoEsitazione.toLowerCase())
        ) {
        //Caso sininistro senza seguito attivo
        if (taskCompletedCheck && (riservaIndennizzoToValidate === 0 /* &&  riservaSpesaCheck === 0 */) && (!checkStatoPagamentoIndennizzo)) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Totale
        if (taskCompletedCheckSenzaPagamentiLqInd && (riservaIndennizzoToValidate === 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato  && listaPagamentiIndennizzo?.length > 0) && checkStatoAllDanneggiati) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            //EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) || 
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Parziale
        if (taskCompletedCheckSenzaPagamentiLqInd &&  (riservaIndennizzoToValidate > 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato && listaPagamentiIndennizzo?.length > 0)){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso Sinistro Completato
        if(taskCompletedCheck && (riservaIndennizzoToValidate === 0) &&(checkArrayStatoPagamentiNoInviato && checkArrayStatoPagamentiNotAllOk && listaPagamentiIndennizzo?.length > 0)){
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return ( !EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          )
        }
        //Caso istruttoria chiusa
        if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
          if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
            return(
              !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
            )
          }
          return true
        }
        //Caso danneggiati non tutti chiusi
        if(!checkStatoAllDanneggiati){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase())
          );
        }
        return false;  // disabled = false
      }
      if(validazioneLimitePagamentoIndennizzo?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
          validazioneLimitePagamentoIndennizzo?.statoEsitazione === ('In attesa')
      ) {
        return(
          !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())
        )
      }
    } 
    return true;
  }
  //Caso Riserva spese
  if(riservaSpesaCheck > authRiservaSpese) {
    if (validazioniIstruttoria && validazioniIstruttoria?.length > 0) {
      const validazioneRiservaSpese = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE');
      if(
        validazioneRiservaSpese?.statoEsitazione === ('In attesa') 
        // &&
        // validazioneRiservaSpese?.valoreDaAutorizzare === riservaSpesaCheck
      ){
        return(
          !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())
        )
      }
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneRiservaSpese?.statoEsitazione.toLowerCase())
        // && 
        // validazioneRiservaSpese?.valoreDaAutorizzare === riservaSpesaCheck
        &&
         (Math.abs(validazioneRiservaSpese?.valoreDaAutorizzare - riservaSpesaCheck) < 1)
      ){
        //Caso sininistro senza seguito attivo
        if (taskCompletedCheck && (riservaIndennizzoToValidate === 0 /* &&  riservaSpesaCheck === 0 */) && (!checkStatoPagamentoIndennizzo)) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Totale
        if (taskCompletedCheckSenzaPagamentiLqInd && (riservaIndennizzoToValidate === 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato  && listaPagamentiIndennizzo?.length > 0) && checkStatoAllDanneggiati) {
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return(
              !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
            )
          }
          return (
            //EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) || 
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso sinsistro chiuso Parziale
        if (taskCompletedCheckSenzaPagamentiLqInd &&  (riservaIndennizzoToValidate > 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato && listaPagamentiIndennizzo?.length > 0)){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          );
        }
        //Caso Sinistro Completato
        if(taskCompletedCheck && (riservaIndennizzoToValidate === 0) &&(checkArrayStatoPagamentiNoInviato && checkArrayStatoPagamentiNotAllOk && listaPagamentiIndennizzo?.length > 0)){
          if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
            if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
              return(
                !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
              )
            }
            return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) &&  !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
          }
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
          )
        }
        //Caso istruttoria chiusa
        if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
          if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
            return(
              !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
            )
          }
          return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) &&  !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
        }
        //Caso danneggiati non tutti chiusi
        if(!checkStatoAllDanneggiati){
          return (
            EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
            EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
            EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase())
          );
        }
        return false
      }
      return true
    }
    return true
  }
  //Caso sininistro senza seguito attivo
  if (taskCompletedCheck && (riservaIndennizzoToValidate === 0 /* &&  riservaSpesaCheck === 0 */) && (!checkStatoPagamentoIndennizzo)) {
    if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
      if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
        return(
          !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
        )
      }
      return (
        !EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase())
      );
    }
    return (
      EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
      EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
    );
  }
  //Caso sinsistro chiuso Totale
  if (taskCompletedCheckSenzaPagamentiLqInd && (riservaIndennizzoToValidate === 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato  && listaPagamentiIndennizzo?.length > 0) && checkStatoAllDanneggiati) {
    if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
      if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
        return(
          !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
        )
      }
      return(
        !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
      )
    }
    return (
      //EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())  ||
      EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) || 
      EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
    );
  }

  //Caso sinsistro chiuso Parziale
  if (taskCompletedCheckSenzaPagamentiLqInd &&  (riservaIndennizzoToValidate > 0 /* && riservaSpesaCheck === 0 */) && (checkArrayStatoPagamentiInviato && listaPagamentiIndennizzo?.length > 0)){
    return (
      EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
      EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
    );
  }
  //Caso Sinistro Completato
  if(taskCompletedCheck && (riservaIndennizzoToValidate === 0) &&(checkArrayStatoPagamentiNoInviato && checkArrayStatoPagamentiNotAllOk && listaPagamentiIndennizzo?.length > 0)){
    if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
      if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
        return(
          !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
        )
      }
      return(
        !EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) &&  !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase())
      );
    }
    return (
      EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
      EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_IN_LAVORAZIONE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
    )
  }
  //Caso istruttoria chiusa
  if(EnumService.CRITERI.ISTRUTTORIA_CHIUSA.includes(queueDesc?.toLowerCase().slice(5))){
    if(checkStatoSomeDanneggiatoOpen && riservaIndennizzoToValidate > 0){
      return(
        !EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
      )
    }
    return (!EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()))
  }
  //Cas danneggiati non tutti chiusi
  if(!checkStatoAllDanneggiati){
    return (
      EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
      EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
      EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase())
    );
  }
  return (
    EnumService.ESITI.SINISTRO_CHIUSO.includes(esitoDescription.toLowerCase()) ||
    EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())   ||
    EnumService.ESITI.SENZA_SEGUITO.includes(esitoDescription.toLowerCase()) ||
    EnumService.ESITI.SINISTRO_COMPLETATO.includes(esitoDescription.toLowerCase()) ||
    EnumService.ESITI.SINISTRO_CHIUSO_TOTALE.includes(esitoDescription.toLowerCase()) ||
    EnumService.ESITI.SINISTRO_PARZIALE.includes(esitoDescription.toLowerCase()) ||
    EnumService.ESITI.SINISTRO_RIAPERTO.includes(esitoDescription.toLowerCase())
  );
}

const checkDisabledPulIstruttAfterValidation = (validazioniIstruttoria, riservaIndennizzoCheck, riservaIndennizzo) => {
  // Controllo iniziale su riservaIndennizzoCheck e riservaIndennizzo
  if (riservaIndennizzoCheck > riservaIndennizzo) {
    // Se c'è almeno un elemento nell'array validazioniIstruttoria
    if (validazioniIstruttoria && validazioniIstruttoria?.length > 0) {
      //cerca la validazione riservato indennizzo con il valore più vicino
      const validazione = validazioniIstruttoria?.find(elemento => ((elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO') &&
                                                                    (Math.abs(elemento?.valoreDaAutorizzare - riservaIndennizzoCheck) < 1)) );
      if (
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
        && (Math.abs(validazione?.valoreDaAutorizzare - riservaIndennizzoCheck) < 1)  
      ) {
        return false;  // disabled = false
      }
      if (validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO' &&
           validazione?.statoEsitazione === 'In attesa')
        {
          return false;  // disabled = true
        }
    }
    // Abbiamo sforato la riserva e di validazioni non c'è traccia 
    return true;  // disabled = true
  }
  return false;  // disabled = false
};

const gestioneEsitiQuitenza = (esito, riservaIndennizzoEur, riservaIndennizzo, listaValidazioni) => {
  //cerca la validazione riservato indennizzo con il valore più vicino
  const validazioneQuitenza = listaValidazioni?.find(elemento => ((elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO') && (Math.abs(elemento?.valoreDaAutorizzare - riservaIndennizzoEur) < 1)));
  if(riservaIndennizzoEur > riservaIndennizzo){
    if(listaValidazioni && listaValidazioni?.length > 0){
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneQuitenza?.statoEsitazione.toLowerCase())
        && (Math.abs(validazioneQuitenza?.valoreDaAutorizzare - riservaIndennizzoEur) < 1)
      ){
        return EnumService.ESITI.IN_VALIDAZIONE.includes(esito.description.toLowerCase()) 
      }
      if(validazioneQuitenza?.statoEsitazione === 'In attesa'){  
        return( 
          EnumService.ESITI.IN_AGENDA.includes(esito.description.toLowerCase()) ||
          EnumService.ESITI.QUIETANZA_EMESSA.includes(esito?.description.toLowerCase())
        )
      }
    }
    return true
  }
  return EnumService.ESITI.IN_VALIDAZIONE.includes(esito.description.toLowerCase())  
}

const disabledPulsantiQuietanza = (riservaIndennizzoEur, riservaIndennizzo, listaValidazioni) => {
  const validazioneQuitenza = listaValidazioni?.find(elemento => ((elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_INDENNIZZO') && (Math.abs(elemento?.valoreDaAutorizzare - riservaIndennizzoEur) < 1)));
  if(riservaIndennizzoEur > riservaIndennizzo){
    if(listaValidazioni && listaValidazioni?.length > 0){
      if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazioneQuitenza?.statoEsitazione.toLowerCase()) 
            && (Math.abs(validazioneQuitenza?.valoreDaAutorizzare - riservaIndennizzoEur) < 1)
      ){
        return false
      }
      if(validazioneQuitenza?.statoEsitazione === 'In attesa'){  
        return true
      }
    }
    return false
  }
  return false
}

const gestioneDisabledEsitiPagIndennizzo = (validazioniIstruttoria, esito, riservaIndennizzo, importoPagamento, pagamentoId,isInstantPaymentAvailable ) => {
  const esitoDescription = esito.description.toLowerCase();

  if(importoPagamento > riservaIndennizzo){
    if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
      const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' && elemento.pagamentoId === pagamentoId);
      if (validazione === undefined) { return  (!EnumService.ESITI.IN_AGENDA.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.PAGAMENTO_INDENNIZZO_NON_ACCETTATO.includes(esitoDescription.toLowerCase())) }
      if(
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase())
        // &&
        // validazione?.valoreDaAutorizzare === importoPagamento
        &&
        (Math.abs(validazione?.valoreDaAutorizzare - importoPagamento) < 1)
      ){
        return EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
      }
      if(
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        validazione?.statoEsitazione === ('In attesa')
      ){
        return !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
      }
    }
    return (!EnumService.ESITI.IN_AGENDA.includes(esitoDescription.toLowerCase()) && !EnumService.ESITI.PAGAMENTO_INDENNIZZO_NON_ACCETTATO.includes(esitoDescription.toLowerCase()));
  }
  if (
    EnumService.ESITI.PAGAMENTO_ISTANTANEO.includes(esitoDescription) &&
    importoPagamento > 0 &&
    pagamentoId && 
    !isInstantPaymentAvailable === false
  ) {
    return !EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription)
  }
  if (
    EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription) &&
    importoPagamento > 0 &&
    pagamentoId && 
    isInstantPaymentAvailable
  ) {
    return !EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription);
  }
  return EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase())
  
}

const gestioneAlertValidazionePagIndennizzo = (validazioniIstruttoria, riservaIndennizzo, pagamentoId, importoPagamento) => {
  if(importoPagamento > riservaIndennizzo){
    if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
      const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' && elemento?.pagamentoId===pagamentoId);
      if (validazione === undefined) { return true; }
      if(
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        validazione?.statoEsitazione === ('In attesa') 
        // && validazione?.valoreDaAutorizzare === importoPagamento
      ){
        return false
      }
      if(
        validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
        // && validazione?.valoreDaAutorizzare === importoPagamento
        &&
        (Math.abs(validazione?.valoreDaAutorizzare - importoPagamento) < 1)
      ){
        return false
      }
    }
    return true
  }
  return false
}

const gestionePulsantiPagIndennizzo = (validazioniIstruttoria, riservaIndennizzo, importoPagamento) => {
  if(importoPagamento > riservaIndennizzo){
      if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
        const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO');
        if(
          validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
          validazione?.statoEsitazione === ('In attesa')
        )
        {
          return true
        }
        if(
          validazione?.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_INDENNIZZO' &&
          EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
          // &&
          // validazione?.valoreDaAutorizzare === importoPagamento
          &&
          (Math.abs(validazione?.valoreDaAutorizzare - importoPagamento) < 1)
        ){
          return false
        }
      }
    return true
  }
  return false
}

const gestioneEsitiPagSpese = (validazioniIstruttoria, esito, riservaSpesa, importoPagamentoSpesa, isInstantPaymentAvailable) => {
  const esitoDescription = esito.description.toLowerCase();
  if(importoPagamentoSpesa > riservaSpesa){
    if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
      const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_SPESE'
                                                        && (Math.abs(elemento?.valoreDaAutorizzare - importoPagamentoSpesa) < 1));
      if(
        validazione?.statoEsitazione === ('In attesa') 
        // &&
        // validazione?.valoreDaAutorizzare === importoPagamentoSpesa
      ){
        return !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
      }
      if(
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
        &&
        (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoSpesa) < 1)
      ){
        return EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
      }
    }
    return true
  }
  if (
    EnumService.ESITI.PAGAMENTO_ISTANTANEO.includes(esitoDescription) &&
    importoPagamentoSpesa > 0 &&
    !isInstantPaymentAvailable === false
  ) {
    return !EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription)
  }
  if (
    EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription) &&
    importoPagamentoSpesa > 0 &&
    isInstantPaymentAvailable
  ) {
    return !EnumService.ESITI.INVIO_ORDINE_PAGAMENTO.includes(esitoDescription);
  }
  return EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
}

const gestioneAlertvalidazionePagSpese = (validazioniIstruttoria, riservaSpesa, importoPagamentoSpesa) => {
  if(importoPagamentoSpesa > riservaSpesa){
    if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
      const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_PAGAMENTO_SPESE'
                                                      && (Math.abs(elemento?.valoreDaAutorizzare - importoPagamentoSpesa) < 1));
      if(
        validazione?.statoEsitazione === ('In attesa') 
        // &&
        // validazione?.valoreDaAutorizzare === importoPagamentoSpesa
      ){
        return false
      }
      if(
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) //EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO
        && (Math.abs(validazione?.valoreDaAutorizzare - importoPagamentoSpesa) < 1)
      ){
        return false
      }
    }
    return true
  }
  return false
}

const gestioneEsitiIncarichi = (authRiservaSpese,  validazioniIstruttoria, esito,riservaSpeseComplessive, importoRiservaSingolo, listaPagamentiSpesaPerIncarico, importoTotalePagamentiSpesaPerIncarico, rispervaSpesaIncarico, criterioIncaricoNominato) => {
  const esitoDescription = esito.description.toLowerCase();
  if(Number(importoRiservaSingolo) > 0) {
    if(riservaSpeseComplessive > authRiservaSpese) {
      if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
        const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE'
                                                        && (Math.abs(elemento?.valoreDaAutorizzare - riservaSpeseComplessive) < 1));
        if(
          validazione?.statoEsitazione === ('In attesa') 
          // && 
          // validazione?.valoreDaAutorizzare === importoRiserva
        ){
          return !EnumService.ESITI.IN_VALIDAZIONE.includes(esitoDescription.toLowerCase()) 
        }
        if(
          EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
          && (Math.abs(validazione?.valoreDaAutorizzare - riservaSpeseComplessive) < 1)
        ){
          return gesioneEsitiIncaricoNominatoHook(esito, listaPagamentiSpesaPerIncarico, importoTotalePagamentiSpesaPerIncarico, rispervaSpesaIncarico, criterioIncaricoNominato)
        }
        if(EnumService.ESITI.SUPERAMENTO_AUTHORITY_RIFIUTATO.includes(validazione?.statoEsitazione.toLowerCase())
        ){
          return true
        }
      }
      return true
    }
    return gesioneEsitiIncaricoNominatoHook(esito, listaPagamentiSpesaPerIncarico, importoTotalePagamentiSpesaPerIncarico, rispervaSpesaIncarico, criterioIncaricoNominato)
  }
  return  gesioneEsitiIncaricoNominatoHook(esito, listaPagamentiSpesaPerIncarico, importoTotalePagamentiSpesaPerIncarico, rispervaSpesaIncarico, criterioIncaricoNominato)
}

const gestioneAlertValidazioneRiservaSpeseIncIstr = (authRiservaSpese, importoRiserva, validazioniIstruttoria) => {
  if(importoRiserva > authRiservaSpese) {
    if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
      const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE' 
                                                       && (Math.abs(elemento?.valoreDaAutorizzare - importoRiserva) < 1));
      if(
        validazione?.statoEsitazione === ('In attesa') 
      ){
        return false
      }
      if(
        EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
        && (Math.abs(validazione?.valoreDaAutorizzare - importoRiserva) < 1)
      ){
        return false
      }
    }
    return true
  }
  return false
}

const gestioneAlertValidazioneRiservaSpeseInc = (authRiservaSpese, validazioniIstruttoria, riservaSpeseComplessive, importoRiservaSingolo) => {
  if(Number(importoRiservaSingolo) > 0) {
    if(riservaSpeseComplessive > authRiservaSpese) {
      if(validazioniIstruttoria && validazioniIstruttoria?.length > 0){
        const validazione = validazioniIstruttoria?.find(elemento => elemento.tipoValidazione === 'VALIDAZIONE_LIMITE_RISERVATO_SPESE'
                                                         && (Math.abs(elemento?.valoreDaAutorizzare - riservaSpeseComplessive) < 1));
        if(
          validazione?.statoEsitazione === ('In attesa') 
           //&& 
           //validazione?.valoreDaAutorizzare === importoRiserva
        ){
          return false
        }
        if(
          EnumService.ESITI.SUPERAMENTO_AUTHORITY_APPROVATO.includes(validazione?.statoEsitazione.toLowerCase()) 
           &&  (Math.abs(validazione?.valoreDaAutorizzare - riservaSpeseComplessive) < 1) 
        ){
          return false
        }
      }
      return true
    }
  }
  return false
}

const gestioneEsitiMarineDenuncia = (esito, numeroPolizza, riservaIndennizzoCheck, riservaIndennizzo) => {
  const esitoDescription = esito.description.toLowerCase()
  if(numeroPolizza === '' || numeroPolizza === null || numeroPolizza?.toLowerCase() === 'non specificato' || numeroPolizza?.toLowerCase() === 'null'){
    return EnumService.ESITI.SINISTRO_APERTO.includes(esitoDescription.toLowerCase())
  }
  if(riservaIndennizzoCheck < riservaIndennizzo){
    return true
  }
  return EnumService.ESITI.SHELL_CLAIM.includes(esitoDescription.toLowerCase())
}

const gestioneEsitiShellClaim = (esito, numeroPolizza) => {
  const esitoDescription = esito.description.toLowerCase()
  if(numeroPolizza === '' || numeroPolizza === null || numeroPolizza?.toLowerCase() === 'non specificato' || numeroPolizza?.toLowerCase() === 'null'){
    return EnumService.ESITI.SINISTRO_APERTO_DA_SHELL_CLAIM.includes(esitoDescription.toLowerCase())
  }
  return false
}

const profileValidation = {
  translateQueueDesc,translateQueueDescLanguage,
  isValidateText,
  isValidCodiceFiscale,
  isValidPartitaIVA,
  isValidPhoneNumber,
  isNumeric,
  isValidEmailNew,
  isItalianIban,
  isSepaIban,
  isValidPIvaOrCodFiscale,
  //validazioneDisabledPulsantiIstruttoria,
  getDisabledEsitiValueIstruttoria,
  checkDisabledPulIstruttAfterValidation,
  gestioneDisabledEsitiPagIndennizzo,
  gestioneAlertValidazionePagIndennizzo,
  gestionePulsantiPagIndennizzo,
  gestioneDisableRiserveSoggCoinvonti,
  gestioneEsitiIncarichi,
  gestioneAlertValidazioneRiservaSpeseIncIstr,
  gestioneAlertValidazioneRiservaSpeseInc,
  gestioneEsitiPagSpese,
  gestioneAlertvalidazionePagSpese,
  gestionePulsanteIcarichiIstruttoria,
  gestioneSelectPagIndennizzo,
  gestioneSelectPagSpese,
  gestioneEsitiMarineDenuncia,
  gestioneEsitiShellClaim,
  gestioneEsitiQuitenza,
  disabledPulsantiQuietanza
};

export default profileValidation;